import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider, useAccount } from "wagmi";
import { config } from "./config";
import React, { useEffect, useState } from "react";
import { WalletOptions } from "./components/wallet-options";
import { Account } from "./components/account";
import DepositModal from "components/DepositModal";
import VerificationModal from "components/VerificationModal";
import ConenctWalletPrompt from "components/ConnectWalletPrompt";

const queryClient = new QueryClient();

function ConnectWallet() {
  const { isConnected, address } = useAccount();
  const [hash, setHash] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [userName, setUserName] = useState("");
  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search);
    // console.log(searchParams.get("hash")); // outputs "m2-m3-m4-m5"
    //@ts-ignore
    setHash(searchParams.get("hash") != null ? searchParams.get("hash") : "");
    //@ts-ignore
    getUserNameFromAddress(address, searchParams.get("hash"));
  }, [address]);
  //@ts-ignore
  async function getUserNameFromAddress(address: `0x${string}`, hash: string) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        address: address,
        network: "ethereum",
        hash: hash,
      }),
    };
    // console.log("THE TX WE SHOULD SEND IS "+tx)
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/userData`,
      requestOptions
    );
    const data = await response.json();
  }
  return isConnected ? (
    <>
      <Account />
      {isVerified ? (
        <>
          <DepositModal network={"ethereum"} />
        </>
      ) : (
        <VerificationModal
          network={"ethereum"}
          hash={hash}
          userName={userName}
          //@ts-ignore
          address={address}
        />
      )}
    </>
  ) : (
    <>
      <ConenctWalletPrompt /> <WalletOptions />
    </>
  );
}

function Ethereum() {
  const submitTransaction = () => {
    // debugger;
  };
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <ConnectWallet />
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default Ethereum;
