import SubmitButtonEth from 'ethereum/components/SubmitButtonEth'
import React, { useState } from 'react'
import SubmitButtonSol from 'solana/SubmitButtonSol'
import {
  Keypair,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
  TransactionInstruction,
  sendAndConfirmTransaction,
} from '@solana/web3.js'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
export type WithdrawModalProps = {
  showHideModal: any
  // any props that come into the component
}
const WithdrawModal = ({ showHideModal }: WithdrawModalProps) => {
  const { publicKey, sendTransaction } = useWallet()
  const { connection } = useConnection()
  const [token, setToken] = useState('')
  const [amount, setAmount] = useState('')

  const handlePostSendSol = async (sig: string) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ signature: sig, tokenId: token, amount: amount }),
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/withdrawSol`,
      requestOptions,
    )
    // debugger;
    const data = await response.json()
    console.log('DATA IS ' + JSON.stringify(data))
    console.log('Return data IS ' + JSON.stringify(data))
  }
  const sendSol = async () => {
    const toPubKey = `${process.env.REACT_APP_SOL_DEPOSIT_ADDRESS}`
    // const keypair = Keypair.fromPublicKey(toPubKey);
    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: new PublicKey(toPubKey),
        lamports: LAMPORTS_PER_SOL / 100,
      }),
    )
    const {
      context: { slot: minContextSlot },
      value: { blockhash, lastValidBlockHeight },
    } = await connection.getLatestBlockhashAndContext()
    // Sign transaction, broadcast, and confirm
    const signature = await sendTransaction(transaction, connection, {
      minContextSlot,
    })
    await connection.confirmTransaction({
      blockhash,
      lastValidBlockHeight,
      signature,
    })

    console.log('SIGNATURE', signature)
    handlePostSendSol(signature)
  }

  return (
    <div className="relative h-32">
      <div className="absolute inset-0 w-1/2 mx-auto my-auto border">
        <div>
          <label className="float-left block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Token
          </label>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="0X...."
            value={token}
            onChange={(e) => setToken(e.target.value)}
          />
          <label className="float-left block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Amount
          </label>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="0"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <button onClick={sendSol}>Withdraw</button>
        </div>
      </div>
    </div>
  )
}

export default WithdrawModal
